import { Toast, Icon, Checkbox, Dialog,Button,Field,Skeleton,Uploader,Image as VanImage,Pagination,DropdownMenu, DropdownItem,Picker,Popup,Overlay,Tab, Tabs,Sticky,Notify} from 'vant';

const Vant = {
    install(Vue) {
        Vue.use(Toast);
        Vue.use(Icon);
        Vue.use(Checkbox);
        Vue.use(Dialog);
        Vue.use(Button);
        Vue.use(Field);
        Vue.use(Skeleton);
        Vue.use(Uploader);
        Vue.use(VanImage);
        Vue.use(Pagination);
        Vue.use(DropdownMenu);
        Vue.use(DropdownItem);
        Vue.use(Picker);
        Vue.use(Popup);
        Vue.use(Overlay);
        Vue.use(Tab);
        Vue.use(Tabs);
        Vue.use(Sticky);
        Vue.use(Notify);
    }
};

export default Vant;