// i18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
	"zh-cn": {
		home: {

		}
	},
	"zh-hant": {
		home: {
			
		}
	},
	"en": {
		home: {
			
		}
	},
};

const i18n = new VueI18n({
	locale: 'en', // 默认语言
	fallbackLocale: 'en', // 未找到翻译时的默认语言
	messages
});

export default i18n;
