import Web3 from 'web3';
import {Notify,Toast} from 'vant';
//number转指定精度
import int2hex from '../utils/int2Hex';
const bigInt = require('big-integer');

import TTTABI from '../../abi/TTTABI.json';
import TicketABI from '../../abi/ticket.json';


// 3T测试链
// const TTTcontractAddress = '0xFDCCe1e8685dD6188a71a0De22A31b87af4B6016';		//3T测试链
// const TicketContractAddress = '0xC39C8d6a6C7ceC627a130472CfbeF2c61023d51d';		//抽奖合约
// const targetChainId = int2hex(91196, 1)	//3T测试链

// 3T主链
const TTTcontractAddress = '0x7826E52F5E31f2D11140d4cba85657F7CaCBBd2a';		//3T主链
// const TicketContractAddress = '0x4597A686aaE504c4C500f482eA235a7c5b4Fce82';		//抽奖合约
const TicketContractAddress = '0xd561d75F49f823d62E8E662905C0b2CD3b19DF14';		//抽奖合约
const targetChainId = int2hex(81188, 1)	//3T测试链

// const PledgeContractAddress = '0xb53706b19f34d79F46C9033851184088bF8df4FB';		//质押逻辑合约地址（原地址）
const decimals = 9;		//精度
var ethereum = null;
var web3 = null;

if(window.ethereum){
    ethereum = window.ethereum;
    web3 = new Web3(window.ethereum);
}else if(window.web3){
    ethereum = window.web3.currentProvider;
    web3 = new Web3(window.web3.currentProvider);
}else{
    Notify('未检测到钱包');
}

window.addEventListener('unhandledrejection', event => {
    console.log('ERROR',event);
    // 记录 Promise 拒绝到日志，而不是输出到控制台
    // console.error('Unhandled promise rejection:', event.reason);
    // 或者将错误信息发送到服务器进行记录
    // sendErrorToServer(event.reason);
});



//将余额*10**decimals
export function handleAmountToWei(value) {
    return bigInt(value * 10 ** decimals);
}
export async function connectWallet() {
    // 获取用户账户
    var account = localStorage.getItem("userAccount");
    const chainId = await ethereum.request({
        method: 'eth_chainId'
    })
    if (chainId !== targetChainId) {
        //切换至目标网络
        const result = await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: targetChainId }],
        }).then(async () => {
            // 获取用户账户
            const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
            account = accounts[0];
            localStorage.setItem("userAccount",accounts[0]);
        }).catch((error) => {
            account = '';
            localStorage.setItem("userAccount",'');
            // 用户可能拒绝了切换网络请求
            this.$Notify.show('用户拒绝请求');
        })
        console.log(result)
    } else{
        // 获取用户账户
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        account = accounts[0];
        console.log(accounts);
        localStorage.setItem("userAccount",accounts[0]);
    }

    if(!account){
        this.$Dialog.alert({
            title: '未获取到钱包账户',
            message: '请刷新页面重新签名授权',
            confirmButtonText: '刷新页面',
            confirmButtonColor: '#FFAB09',
            cancelButtonText: '关闭Dapp',
            showCancelButton: true,
        }).then(() => {
            //刷新页面
            window.location.reload();
        }).catch(() => {
            //关闭整个页面
            window.close()
            return;
        })
    }

    // 监听账户变化事件
    ethereum.on('accountsChanged', (accounts) => {
        console.log('账户变化了', accounts)
        if (accounts.length > 0) {
            account = accounts[0];
            //写入本地存储
            localStorage.setItem("userAccount",accounts[0]);
            // this.$emit('custom-event', accounts[0]);
            this.changeAccount(accounts[0]);
        } else {
            // 用户可能注销了账户，你可以在这里进行相应的处理
            this.$Notify.show('注销账户');
        }
    });
    return account;
   
}
//查询基本信息,通过合约BasicInformation方法
export async function getBasicInformation() {
    const contract = new web3.eth.Contract(TicketABI, TicketContractAddress);
    const result = await contract.methods.BasicInformation().call();
    return result;
}

//查询玩家列表getPlayer,传入期数
export async function getPlayer(stage) {
    const contract = new web3.eth.Contract(TicketABI, TicketContractAddress);
    const result = await contract.methods.getPlayers(stage).call();
    return result;
}
//查询授权额度allowance
export async function getAllowance() {
    const TTTcontract = new web3.eth.Contract(TTTABI, TTTcontractAddress);
    const allowance = await TTTcontract.methods.allowance(localStorage.getItem("userAccount"),TicketContractAddress).call();
    return allowance;
}
//报名参与signUp
export async function signUp(entryFee) {
    //查询服务是否正常
    const old_server = localStorage.getItem("server");
    const contract = new web3.eth.Contract(TicketABI, TicketContractAddress);
    const server = await contract.methods.getServer().call();
    localStorage.setItem("server",server);
    console.log('之前保存的服务状态',old_server);
    console.log('服务状态',server);
    if(!server){
        Notify('暂停服务!平台维护调整参数中,请稍后再试');
        return;
    }
    if(old_server!==null && !!old_server != server){
        Notify('服务已恢复,即将刷新页面');
        //刷新页面
        setTimeout(() => {
            window.location.reload();
        }, 2000);
        return;
    }

    //查询授权额度
    const TTTcontract = new web3.eth.Contract(TTTABI, TTTcontractAddress);
    const allowance = await TTTcontract.methods.allowance(localStorage.getItem("userAccount"),TicketContractAddress).call();
    console.log(allowance,entryFee);
    if(allowance < entryFee){
        Notify('授权额度不足');
        const approve_res = await approve(entryFee);
        if(!approve_res.status){
            return;
        }
    }

    Toast.loading({
        message: '报名操作中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0,
    });
    try {
        const contract = new web3.eth.Contract(TicketABI, TicketContractAddress);
        const signUp_result = await contract.methods.signUp().send({from:localStorage.getItem("userAccount")});
        console.log('报名结果',signUp_result);
        if(signUp_result.status){
            Notify({
                type: 'success',
                message: '报名成功',
                duration: 1000,
                onClose: () => {
                    Toast.clear();
                },
            });
        }else{
            Toast.clear();
            Notify('报名失败');
        }
        return signUp_result;
    } catch (error) {
        Toast.clear();
        Notify('报名失败');
    }
}

//通用授权3T额度,需传入被授权的合约地址，授权金额
export async function approve(amount){
    Toast.loading({
        message: '授权操作中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0,
    });
    try {
        const TTTcontract = new web3.eth.Contract(TTTABI, TTTcontractAddress);
        // 调用合约的 deployToken 方法
        const result = await TTTcontract.methods.approve(
            TicketContractAddress,
            amount,
        ).send({
            from: localStorage.getItem("userAccount"),
        });
        console.log('授权结果',result)
        if(result.status){
            Notify({
                type: 'success',
                message: '授权成功',
            });
        }else{
            Toast.clear();
            Notify('授权失败');
        }
        return result;
    }
    catch (error) {
        Toast.clear();
        Notify('授权失败');
    }
}
//查询优胜信息getWinerInfo
export async function getWinerInfo(stage) {
    const contract = new web3.eth.Contract(TicketABI, TicketContractAddress);
    const result = await contract.methods.winnerInfo(stage).call();
    return result;
}

