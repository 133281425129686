import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// axios
import axios from 'axios'
Vue.prototype.$axios = axios
//ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import Web3 from 'web3';
// if (window.ethereum) {
// 	window.web3 = new Web3(window.ethereum);
// 	try {
// 		// 请求用户授权
// 		window.ethereum.enable();
// 	} catch (error) {
// 		console.error('用户拒绝了授权请求');
// 	}
// } else if (window.web3) {
// 	window.web3 = new Web3(window.web3.currentProvider);
// } else {
// 	console.error('请安装MetaMask插件或使用支持的以太坊浏览器');
// }

//i18n
import i18n from './utils/i18n.js';

//copy
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

//vant
import Vant from './utils/vant.js';
import 'vant/lib/index.css'; // 引入 Vant 样式文件
import './styles/variables.scss'; // 引入自定义样式文件
Vue.use(Vant);

//全局引入utils.js
// import {numToK} from './utils/utils';
import * as utils from './utils/utils';
Vue.prototype.$utils = utils;

//折线图
// import VCharts from 'v-charts-v2'
// Vue.use(VCharts)

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app')
