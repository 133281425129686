<template>
	<div class='container'>
		<div class='wallte'>
			<img src="../assets/wallet.png" alt="" />
			<span v-if="userAccount">{{ hanldeAddress(userAccount) }}</span>
			<span v-else @click="connectWallet">点击链接钱包</span>
		</div>
		<div class="head">
			<div class='title'>
				<span>3T抽奖区</span>
			</div>
			<span class="beforeApprove" @click="openApprove">提前授权</span>
		</div>


		<div class='rules' v-if="basicInformation">
			<div class='rule_title'>玩法介绍:</div>
			<div>每次参与抽奖门票费: <span class="red">{{ handleDecimals(basicInformation._entryFee) }} 3T</span></div>
			<div>每轮满 <span class="red">{{ basicInformation._roundSize }}</span> 人自动开奖</div>
			<div>中奖者获得奖金为: 参与人数 * 门票费</div>
			<div>每轮将抽取总奖金的 <del>10%</del> <span class="red">{{ 100 - parseInt(basicInformation._bonusRate)
					}}%</span>,作为手续费</div>
			<div>每轮仅有一位玩家中奖,每个地址可多次购买门票</div>
			<div class="ps" style="margin-top: 6px;">*本游戏实现完全去中心化,所以操作均由链上合约实现</div>
			<div class="ps">*您可点击查看合约重要接口 <span class="to_code" @click="to_code">「合约披露」</span></div>
			
		</div>

		<div class="Jackpot" v-if="basicInformation">
			<div class="pledgeData">
				<div>
					<div>本期期数</div>
					<div>{{ basicInformation._stage }}</div>
				</div>
				<div>
					<div>参与人数</div>
					<div>{{ basicInformation._playerNum }}</div>
				</div>
				<div>
					<div>优胜奖金</div>
					<div>{{ handleDecimals(basicInformation._roundSize * basicInformation._entryFee) }}</div>
				</div>
			</div>
			<div class='signUpBox'>
				<div class='signUp' @click="_signUp">报名参与</div>
			</div>
		</div>
		<div class="list">
			<div class='list_top'>
				<div class='list_title'>{{ select_stage }}</div>
				<!-- <span>选择期数</span> -->
				<span class="history" @click="showPicker = true">往期数据</span>
			</div>
			<div v-if="winnerInfo" class='lucky'>
				<div>幸运儿(随机种子:{{ winnerInfo.index }})</div>
				<div class='win_address'>{{ winnerInfo.user }}</div>
				<div>中奖金额</div>
				<div class='win_address'>{{ handleDecimals(winnerInfo.bonus) }}</div>
			</div>
			<div class='listItem' v-if="players.length > 0">
				<div>玩家列表</div>
				<div class="item" v-for="(item, index) in players" :key='index'>
					<div>
						<span class="number">{{ index + 1 }}</span>
						<span>{{ hanldeAddress(item) }}</span>
					</div>
					<span>{{ hanldeTime(players_time[index]) }}</span>
				</div>
			</div>
			<div v-else class="none">
				<img src="../assets/no_record.png" alt="">
				<div class="tips">暂无玩家参与</div>
			</div>
		</div>
		<div class="author">
			<div>Author:@Ethereal</div>
			<div>此Dapp项目与3T官方无关,纯属娱乐</div>
		</div>
		<van-popup v-model="showPicker" round position="bottom">
			<van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
		</van-popup>

		<van-dialog v-model="showDialog" title="提前授权/撤销授权" show-cancel-button @confirm="confirmAmount">
			<van-field v-model="approveAmount" type="digit" label="输入授权额度" :border="true"
				:clickable="true" input-align="left" class="inputAmount" placeholder="0"/>
			<div class="tishi">
				<div>当前授权额度:{{ allowanceAmount }}</div>
				<div>提前授权可在报名时免去授权流程</div>
				<div>输入授权为0时,表示撤销对合约的授信额度</div>
			</div>
		</van-dialog>
	</div>
</template>

<script>
import Web3 from 'web3';
import { connectWallet, getBasicInformation, getPlayer, signUp, getWinerInfo, approve, getAllowance } from '../utils/eth_methods.js';
import {Notify,Toast} from 'vant';
export default {
	data() {
		return {
			userAccount: '',
			select_stage: '第1期',
			columns: [],
			basicInformation: null,
			winnerInfo: null,
			showPicker: false,
			stageIndex: 0,
			players: [],
			players_time: [],
			showDialog: false,
			approveAmount: 500,
			allowanceAmount: 0
		};
	},
	async mounted() {
		Toast.loading({
			message: '加载中...',
			forbidClick: true,
			loadingType: 'spinner',
			duration: 0,
		});
		console.log(this.columns);
		this.userAccount = await connectWallet()
		// 如果连接成功，可以在这里执行其他逻辑，例如获取基本信息
		if (this.userAccount) {
			await this._getBasicInformation();
			// this.getPlayer(this.stage);
		}
		Toast.clear();
	},
	//离开页面时取消监听滚动
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll);
	},

	methods: {
		connectWallet,
		getBasicInformation,
		getPlayer,
		signUp,
		getWinerInfo,
		approve,
		getAllowance,
		//查询基本信息
		async _getBasicInformation() {
			const basicInformation = await getBasicInformation();
			console.log('更新基本信息', basicInformation);
			this.basicInformation = basicInformation;
			if (this.columns.length < basicInformation._stage) {
				var arr = [];
				for (let i = basicInformation._stage; i > 0; i--) {
					arr.push('第' + i + '期');
				}
				this.columns = arr;
			}
			if (basicInformation._stage > 0) {
				this.select_stage = '第' + basicInformation._stage + '期';
				this.stageIndex = 0;
				//获取玩家列表
				await this.getPlayer(basicInformation._stage).then(res => {
					this.players = res[0];
					this.players_time = res[1];
				});
			}
		},
		async _signUp() {
			await signUp(this.basicInformation._entryFee).then(res => {
				if (res && res.status) {
					this._getBasicInformation();
				}
			})
		},
		handleDecimals(value) {
			return Web3.utils.fromWei(value, 'gwei');
		},
		hanldeAddress(address) {
			if (!address) return '';
			return address.slice(0, 8) + '...' + address.slice(-8);
		},
		//时间戳转换
		hanldeTime(time) {
			// return new Date(parseInt(time) * 1000).toLocaleString();
			//转为YYYY-MM-DD HH:MM:SS格式
			var date = new Date(parseInt(time) * 1000);
			var Y = date.getFullYear() + '-';
			var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
			var D = date.getDate() + ' ';
			var h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
			var m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
			var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
			return Y + M + D + h + m + s;
		},
		//选择往期数据
		onConfirm(value, index) {
			console.log(value, index);
			this.showPicker = false;
			this.stageIndex = index;
			this.select_stage = value;
			this.getPlayer(parseInt(this.basicInformation._stage) - index).then(res => {
				this.players = res[0];
				this.players_time = res[1];
			});
			if (index != 0) {
				this.getWinerInfo(parseInt(this.basicInformation._stage) - index).then(res => {
					console.log(res);
					this.winnerInfo = res;
				});
			} else {
				this.winnerInfo = null;
			}
		},
		//打开提前授权弹窗
		async openApprove() {
			this.getAllowance().then(amount => {
				console.log(amount)
				this.allowanceAmount = Web3.utils.fromWei(amount, 'gwei');
				console.log(this.allowanceAmount)
			});
			this.showDialog = true;
		},
		//提前授权
		async confirmAmount() {
			this.showDialog = false;
			var amount = Web3.utils.toWei(this.approveAmount, 'gwei');
			console.log(amount);
			await this.approve(amount).then(res => {
				if (res && res.status) {
					this.$toast('授权成功');
				}
			})
		},
		//跳转合约重要接口披露
		to_code() {
			this.$router.push({ path: '/code' });
		}
	},

}
</script>

<style scoped lang="scss">
.App {
	background-color: #f2f2f2;
	height: 100vh;
}

.red {
	color: #FF5722;
	font-size: 15px;
	font-weight: bold;
}

.container {
	padding-top: 44px;

}

.wallte {
	position: fixed;
	z-index: 9;
	border-bottom: 1px solid #f2f2f2;
	top: 0;
	background-color: #fff;
	left: 0;
	right: 0;
	height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}
}

.head {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
}

.title {
	font-size: 24px;
	position: relative;
	width: fit-content;
	font-weight: 500;

	::after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0;
		width: 50%;
		height: 3px;
		background: #F19F01;
	}
}

.beforeApprove {
	font-size: 14px;
	color: #F19F01;

}

.rules {
	background: #fff;
	padding: 10px 20px;
	font-size: 14px;
	color: #272822;

	.rule_title {
		margin-bottom: 5px;
		font-size: 18px;
		font-weight: bold;
		color: #F19F01;
	}
	.ps{
		font-size: 12px;
		color: #FF5722;
	}
	.to_code{
		color: #F19F01;
		cursor: pointer;
	}
}


.Jackpot {
	margin-top: 20px;
	background: #FFFFFF;
	border-radius: 10px;
	padding: 20px;
	position: relative;

	.stage {
		text-align: right;
		color: #F19F01;
	}

	.title {
		font-size: 1.17rem;
		font-family: PingFang SC;
		color: #000000;

		span {
			margin-right: 0.3rem;
		}
	}

	.total {
		font-size: 2.5rem;
		font-family: monospace;
		font-weight: bold;
		color: #F19F01;
		margin-top: 0.3rem;
	}

	.pledgeData {
		display: flex;
		justify-content: space-between;
		align-items: center;

		>div {
			text-align: center;

			>div:first-child {
				font-size: 16px;
				color: #000000;
			}

			>div:last-child {
				font-size: 18px;
				font-weight: 500;
				color: #000000;
			}
		}
	}

	.signUp {
		margin: 25px auto 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		background: #F19F01;
		color: #fff;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		font-size: 14px;
		/* 闪烁动画 */
		animation: blink 1s infinite;
		/* 光环边框 */
		box-shadow: 0 0 10px 2px #ffc659;
	}

}

.list {
	margin-top: 20px;
	background: #FFFFFF;
	border-radius: 10px 10px 0 0;
	padding: 20px;
	position: relative;

	.list_top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		line-height: 1;
	}

	.list_title {
		font-size: 16px;
		font-family: PingFang SC;
		color: #000000;
		border-left: 4px solid #4CAF50;
		padding-left: 8px;
	}

	.history {
		font-size: 16px;
		font-family: PingFang SC;
		color: #F19F01;
	}

	.lucky {
		padding: 10px 0;
		text-align: center;
		color: #FF5722;
		border-left: 3px solid #ffc659;
		border-right: 3px solid #ffc659;
		background-color: #f2f2f2;

		.win_address {
			font-size: 14px;
			line-height: 1;

		}

		.win_address:not(:last-child) {
			margin-bottom: 5px;
		}
	}

	.listItem {
		text-align: center;
		margin-top: 20px;
		min-height: 300px;
		max-height: 500px;
		overflow: hidden auto;

		.item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;

			span {
				font-size: 14px;
				color: #000;
			}

			>div {
				display: flex;
				align-items: center;
			}
		}

		.number {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 22px;
			height: 22px;
			margin-right: 5px;
			border-radius: 50%;
			font-size: 12px !important;
			background: #F19F01;
			color: #fff !important;
		}
	}
}

/* 闪烁动画 */
@keyframes blink {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(0.9);
	}

	100% {
		transform: scale(1);
	}
}

.none {
	padding-bottom: 20px;
	font-size: 16px;
	color: #999;
	text-align: center;

	.tips {
		line-height: 1;
		margin-top: -20px;
	}

	img {
		width: 60%;
	}
}

.tishi {
	font-size: 12px;
	color: #F19F01;
	margin-top: 10px;
	text-align: center;
	margin-bottom: 20px;
}
.author{
	text-align: center;
	color: #999;
	font-size: 12px;
	padding: 20px 0;
}
</style>

<style>
.el-loading-mask {
	background-color: rgb(255 255 255 / 50%);
}
.inputAmount{
	margin-top: 10px !important;
	background: #f2f2f2!important;
	input{
		background: #fff!important;
		padding-left: 12px!important;
	}
}
</style>